/* TeamPage.css */
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,300;9..40,700&family=Lora:wght@400;600&display=swap');

.grid-container {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Adjust the space between rows */
}

.flex-row {
  display: flex;
  gap: 10px; /* Adjust the space between items */
}

.flex-cell {
  flex: 1;
  display: flex;

  align-items: center;
}


.spacer {
    display: block;
    padding: 40px;
}

/* CreativeTechCollective.css */
.collective-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 20px;
  }
  
  .collective-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 100px;
    margin: auto;
  }
  
   h1 {
    font-size: 40px; /* Adjust size as needed */
    font-weight: bold;
    font-family: 'DM Sans';
    width: 90%;
  }

  h3 {
    font-size: 24px; /* Adjust size as needed */
    font-weight: bold;
    margin-bottom: 20px;
    color: #565555;
    font-family: 'DM Sans';
    width: 90%;
  }
  
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .collective-content {
      grid-template-columns: 1fr;
    }
  }
  

.team-container {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .header {
    padding: 50px 20px;
    text-align: left;
    width: 60%;
  }
  
  .team-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-top: 50px;
  }

  
  
  .team-member {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .team-member img {
    width: 100%;
    height: 464px;
    object-fit: cover; /* This will ensure the images cover the area, change as needed */
  }
  
  .team-member h3 {
    margin: 10px 0 5px;
  }
  
  .team-member p {
    margin: 0;
  }
  
  .meetup-invite {
    text-align: center;
    margin-top: -66px;
    display: flex;
    align-items: center;
    justify-content: center;

  }
  
  .meetup-invite button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: #F0F0F0;
    color: #000;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .meetup-invite button:hover {
    background-color: #000;
    color: #fff;
  }

  .visionaries h2 {
    font-size: 40px;
    font-weight: 400;
  }
  
  /* Responsive Styles */
  @media (max-width: 1024px) {
    .team-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 768px) {
    .team-grid {
      grid-template-columns: 1fr; /* Stack them on smaller screens */
    }
    .header {
        width: 90% !important;
    }

    .meetup-invite {
        margin-top: 0 !important;
    }

    .close-button {
        top: 30px !important;
    }
    .aboutcontent {
        width: 100% !important;
        top: 0px !important;
    }
    .aboutcontent h2 {
        font-size: 20px !important;
    }
    .spacer {
        display: block;
        padding: 0px !important;
    }

    .flex-row {
      flex-direction: column;
    }
  }

  .name-card {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px; /* Adjust the gap as needed */
    padding: 20px;
    background-color: #F0F0F0;
  }
  
  .name-card h1 {
    font-size: 24px; /* Adjust font size as needed */
    margin: 0;
    font-weight: normal; /* Adjust font weight as needed */
  }
  
  .name-card p {
    font-size: 15px; /* Adjust font size as needed */
    margin: 0;
    text-align: left;
    color: #000; /* Adjust text color as needed */
  }
  
  .name-card img {
    width: 32px; /* Adjust image size as needed */
    height: auto;
  }
  
  small {
    color: #B3B3B3;
  }
  
  /* You can add additional media queries for other screen sizes if needed */
  
  /* NameCard.css */
.name-card {
    display: flex;
    justify-content: space-between;
    align-items: center; /* Align items vertically */
    padding: 20px;
    background: #000; /* Assuming a white background; change as needed */  }
  
  .name-card-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 20vw;
  }
  
  .name-card-text h1 {
    font-size: 18px; /* Adjust the size as needed */
    margin: 0 0 5px 0; /* Adjust margin as needed */
    font-weight: normal; /* Adjust weight as needed */
    text-align: left;
    color: #fff;
   
  }
  
  .name-card-text p {
    font-size: 15px; /* Adjust the size as needed */
    margin: 0;
    text-align: left;
    color: #B3B3B3; /* Adjust color as needed */
  }
  
  .linkedin-logo {
    margin-left: auto; /* Pushes the LinkedIn logo to the right */
  }
  
  .linkedin-logo img {
    width: 32px; /* Adjust size as needed */
    height: auto;
  }
  
  /* Add media queries for responsiveness if needed */
  

  .team-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 20px;
  }
  
  .team-member {
    flex-basis: 30%; /* Adjust size for responsiveness */
    text-align: center;
    margin-bottom: 20px;
  }
  
  .team-member img {
    width: 100%; /* Responsive image size */
    height: auto;

  }

  .team-member p {
    font-size: 12px;
    color: #fff;
    text-align: left;
  }
/* body, .cursor-overlay {
  cursor: none;
} */

.hamburger-icon {
  position: fixed;
  top: 20px;
  left: 20px;
  font-size: 30px;
  cursor: pointer;
  z-index: 1000;
  color: #fff;
}

.menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.9);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.menu ul {
  list-style: none;
  padding: 0;
}

.menu li {
  margin: 20px;
  font-size: 24px;
}


.white {
color: #fff;
}

.glowing-icon {
cursor: pointer;
width: 50px;
height: 50px;
border-radius: 50%;
background-color: white;
box-shadow: 0 0 8px 2px rgba(255, 255, 255, 0.8);
position: fixed;
right: 20px;
bottom: 20px;
transition: box-shadow 0.3s ease-in-out;
}

.glowing-icon:hover {
box-shadow: 0 0 12px 4px rgba(255, 255, 255, 1);
}


html {
background-color: #000000;
}

.about-overlay {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background: rgba(0, 0, 0, 0.90);
display: flex;
align-items: center;
justify-content: center;
z-index: 1000;
}

.cursor-overlay {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
cursor: none; /* Hide default cursor */
pointer-events: none; /* Allows clicks to pass through to elements below */
z-index: -9;
}

.cursor {
position: absolute;
width: 50px;
height: 50px;
background-color: black;
border-radius: 50%;
pointer-events: none;
}


@keyframes pulse-animation {
0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
}

100% {
    transform: translate(-50%, -50%) scale(2);
    opacity: 9;
}
}

.intro {
color: #000;
user-select: none
}

.intro_white {
font-size: 223pt;
color: #fff;
user-select: none
}




.pulse {
transform: translate(-50%, -50%) scale(3);
opacity: 0;
}


.aboutcontent {
position: relative;
background-color: white;
padding: 40px;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
border-radius: 8px;
width: 50%;
text-align: left;

}

.close-button {
position: absolute;
top: 10px;
right: 10px;
border: none;
background: none;
cursor: pointer;
font-size: 24px;
}

/* You can further style the button to make it look more like an 'X' */
.close-button:hover {
color: #ff0000; /* Change as needed */
}


.navbar {
display: flex;
max-width: 1400px;
margin: 0px auto;
justify-content: space-between;
align-items: center;
padding: 10px 20px;
background: #F0F0F0; /* Or any other color as per your design */
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: adds shadow for depth */
}

.nav-brand {
font-size: 12px; /* Adjust size as needed */
font-weight: normal; /* Adjust weight as needed */
}

.nav-links a {
text-decoration: none;
color: #000000; /* Or any other color as per your design */
margin-left: 20px; /* Space between links */
font-size: 12px; /* Adjust size as needed */
}

/* You might want to add :hover effects or active class styling */
.nav-links a:hover {
text-decoration: underline;
}
.team-member {
display: flex;
align-items: center;
justify-content: space-between;
margin-bottom: 20px;
}

.member-info {
flex: 1;
padding-right: 20px;
}

.member-splats {
flex: 1;
}





.team-container {
display: flex;
flex-wrap: wrap;
justify-content: space-around;
}

.team-member {
display: flex;
flex-direction: column;
align-items: center;
border: 1px solid #fff;
}

.image-container {
position: relative;
padding: 5px;

}

.profile-image {
width: 100%;
height: 100%;
object-fit: cover;
}

.splat-overlay {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
pointer-events: none;  
}


input {
width: 30vw;
  padding: 12px;
  margin-top: 20px;
  margin-right: 20px;
}


.footer {
display: flex;
justify-content: space-between;
align-items: center;
padding: 40px;
background-color: black;
color: white;
}

.footer-content {
display: flex;
justify-content: space-between;
width: 100%;
}

.footer-section {
display: flex;
flex-direction: column-reverse;
}

.left h1, .right h2 {
font-size: 48px;
font-weight: bold;
}

button {

border: 1px solid white;
padding: 10px 20px;
font-size: 16px;
cursor: pointer;
margin-top: 20px;
}

.social-links {
margin-top: 20px;
}

.social-links a {
text-decoration: none;
color: white;
margin-right: 20px;
}

small {
margin-top: 20px;
display: block;
}

@media (max-width: 768px) {
.team-member {
  flex: 1 1 100%; /* Each team member takes full width on mobile */
}

.mobile {
  font-size: 20px;
}

.name-card-text {
  width: 70vw !important;
}

.mobilehide {
  display: none !important;
}
}